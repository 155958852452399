import { PAGE_URLS } from '~/config/page-url'
import { PATH_GAME_URL, PATH_GAME_MB_URL, PATH_IMAGES } from '~/constants/path'

export const GAME_PAGE_URL = {
  shooting_fish: `${PAGE_URLS.GAME}/ban-ca`,
  slots: `${PAGE_URLS.GAME}/slots`,
  nohu: `${PAGE_URLS.GAME}/no-hu`,
  card_game: `${PAGE_URLS.GAME}/game-bai`,
  quay_so: `${PAGE_URLS.GAME}/quay-so`,
  lotte: `${PAGE_URLS.GAME}/lo-de`,
  table_game: `${PAGE_URLS.GAME}/table-game`,
  quick_game: `${PAGE_URLS.GAME}/game-nhanh`,
  all: `${PAGE_URLS.CASINO}`
}

export const PROMOTION_URL = {
  olympic_2024: `${PAGE_URLS.PROMOTION}/gio-vang-dat-cuoc-bong-da-tai-da88-thuong-100K`,
  promotion_158: `${PAGE_URLS.PROMOTION}/hoan-tra-the-thao-158-moi-ngay-tai-da88`,
  refund: `${PAGE_URLS.PROMOTION}/hoan-tien-ve-thua-dau-ngay-1-trieu-tai-da88`,
  promotion_05: `${PAGE_URLS.PROMOTION}/can-quet-game-bai-doi-thuong-da88-hoan-tra-bat-tan`,
  funding: `${PAGE_URLS.PROMOTION}/da88-cap-von-3-lan-tien-nap`,
  euro2024: `${PAGE_URLS.PROMOTION}/cuoc-bong-da-euro-nhan-thuong-bat-ngo-tu-da88`,
  copa2024: `${PAGE_URLS.PROMOTION}/thuong-100K-cuoc-bong-da-copa-america-tai-da88-moi-ngay`
}

export const SORT = [
  {
    nameMB: 'Mặc Định',
    name: 'Mặc định',
    icon: `${PATH_GAME_URL}icon-sort-default.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-default-mb.webp`,
    type: 'default',
    typeUrl: '',
    isShow: true
  },
  {
    nameMB: 'Yêu thích',
    name: 'Yêu thích',
    icon: `${PATH_GAME_URL}icon-sort-favourite.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-newber-mb.svg`,
    type: 'FOR_NEWBER',
    typeUrl: 'game-cho-nguoi-moi',
    isShow: true
  },
  {
    nameMB: 'Chơi Nhiều',
    name: 'Chơi nhiều',
    icon: `${PATH_GAME_URL}icon-sort-player-mb.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-player-mb.svg`,
    type: 'TOP_USER_BET',
    typeUrl: 'game-duoc-nhieu-nguoi-choi',
    isShow: true
  },
  {
    nameMB: 'Game Hot',
    name: 'Game Hot',
    icon: `${PATH_GAME_URL}icon-sort-hot.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-hot-mb.webp`,
    type: 'HOT_GAME',
    typeUrl: 'game-dang-hot',
    isShow: true
  },
  {
    nameMB: 'Game Mới',
    name: 'Game mới',
    icon: `${PATH_GAME_URL}icon-sort-new.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-new-mb.webp`,
    type: 'NEW_GAME',
    typeUrl: 'game-moi',
    isShow: true
  },
  {
    nameMB: 'Cược Nhiều',
    name: 'Cược nhiều',
    icon: `${PATH_GAME_URL}icon-sort-bets.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-bets-mb.webp`,
    type: 'TOP_BET',
    typeUrl: 'game-duoc-cuoc-nhieu',
    isShow: true
  }
]

export const EMPTY_CONTENT = [
  {
    icon: `${PATH_IMAGES}icon/icon-empty.svg`,
    text: 'Đang cập nhật dữ liệu'
  },
  {
    icon: `${PATH_GAME_URL}icon-empty-data.webp`,
    text: 'Đang cập nhật dữ liệu'
  }
]

export const PACKAGE_ID_BLOCK_GAME = [2, 3, 4, 5, 6, 7]

export const PAGE_SHOW_MINIGAME = [
  'the-thao',
  'the-thao-id',
  'game',
  'game-type',
  'livecasino',
  'livecasino-type',
  'lode',
  'index'
]
